.row {
  display: flex;
  align-items: space-around;
  justify-content: space-around;
  margin: 30px 120px 120px 120px;
  flex-wrap: wrap;
}

.circle-text {
  display: flex;
  height: 150px; /*change this and the width for the size of your initial circle*/
  width: 150px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  /* background: rgb(231, 92, 92); */
  background: #2b2b2b;
  color: #fff;
  font: 20px 'josefin sans', arial; /*change this for font-size and font-family*/
  text-shadow: 2px 2px 5px black;
  align-items: center;
  justify-content: center;
  margin: 20px;
  box-shadow: inset 0 0 0 0 rgba(200, 95, 66, 0.4),
    inset 0 0 0 3px rgba(255, 255, 255, 0.6), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

.circle-text:hover {
  /* background: rgb(231, 56, 56); */
  background: #ff9000;
  font: 22px 'josefin sans', arial;
  text-shadow: 2px 2px 5px blue;
}

@media (max-width: 600px) {
  .circle-text {
    height: 130px; /*change this and the width for the size of your initial circle*/
    width: 130px;
    font: 18px 'josefin sans', arial;
    margin: 10px;
  }
  .row {
    margin: 0px;
  }
}
