.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 100px;
  height: 100%;
  margin-bottom: 200px;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #fff;
  top: 0;
  bottom: 0;
  left: 20%;
  margin-left: -3px;
}

/* Container around content */
.container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 55%;
  padding-left: 70px;
  padding-right: 25px;
  z-index: 0;
  margin-top: 20px;
}

/* The circles on the timeline */
.container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: #2b2b2b;
  border: 4px solid #db5800;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 20%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: #2b2b2b;
  position: relative;
  border-radius: 6px;
  color: black;
  transition: transform 0.2s;
}
.content:hover {
  /* -webkit-box-shadow: 9px 8px 51px 4px rgba(219, 88, 0, 1);
  -moz-box-shadow: 9px 8px 51px 4px rgba(219, 88, 0, 1);
  box-shadow: 9px 8px 51px 4px rgba(219, 88, 0, 1); */
  transform: scale(1.1);
}

.year {
  color: white;
  font-size: 24px;
}

.educationText {
  text-align: justify;
  word-wrap: break-word;
  font-weight: 300;
  line-height: 1.3;
  color: #fff8dc;
  font-size: 16px;
  padding-top: 12px;
}

@media (max-width: 600px) {
  .timeline {
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 70px;
  }

  /* The actual timeline (the vertical ruler) */
  .timeline::after {
    left: 10%;
  }

  /* Container around content */
  .container {
    padding: 0px;
    width: 70%;
    padding-left: 45px;
    padding-right: 10px;
  }

  /* The circles on the timeline */
  .container::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }

  /* Place the container to the right */
  .right {
    left: 10%;
  }

  /* The actual content */
  .content {
    padding: 20px;
    position: relative;
    border-radius: 6px;
  }
  .content:hover {
    transform: scale(1.1);
  }

  h2 {
    font-size: 26px;
  }
}
