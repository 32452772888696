@import url(http://fonts.googleapis.com/css?family=PT+Sans|Fjalla+One);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
html {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}
q,
blockquote {
  quotes: none;
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: '';
  content: none;
}
a img {
  border: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Fjalla One', sans-serif;
  font-weight: normal;
  line-height: normal;
}
h1 {
  font-size: 35px;
  color: #fff;
}
h2 {
  font-size: 32px;
  color: #fff;
}
h3 {
  font-size: 24px;
  color: #fff;
}
h4 {
  font-size: 22px;
  color: #fff;
}
.hidden {
  display: none;
}

@font-face {
  font-family: 'fontello';
  font-weight: normal;
  font-style: normal;
}

@-webkit-keyframes backgroundgrad {
  0% {
    background-position: 9% 0%;
  }
  50% {
    background-position: 92% 100%;
  }
  100% {
    background-position: 9% 0%;
  }
}
@keyframes backgroundgrad {
  0% {
    background-position: 9% 0%;
  }
  50% {
    background-position: 92% 100%;
  }
  100% {
    background-position: 9% 0%;
  }
}

@media (min-width: 600px) {
  .hexagon {
    width: 204px;
    height: 236px;
    overflow: hidden;
    /* visibility: hidden; */
    float: left;
    display: block;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    z-index: 10;
    outline: none;
  }
  .hexagon div.hexagon-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }
  .hexagon div.hexagon-in2 {
    color: #fff;
    overflow: hidden;
    visibility: visible;
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }
  .hexagon .inner {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    visibility: visible;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .hexagon .test {
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    visibility: visible;
    display: block;
    text-decoration: none;
    font-family: 'Fjalla One', sans-serif;
    font-size: 45px;
    display: block;
    -webkit-transition: -webkit-transform 0.1s;
    transition: -webkit-transform 0.1s;
    transition: transform 0.1s;
    transition: transform 0.1s, -webkit-transform 0.1s;
  }

  .hexagon .test:active {
    color: #333;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  /* 4 upper hexagons */
  .upper {
    padding: 110px 0px;
  }
  /* 4 lower hexagons */
  .lower {
    padding: 80px 0px;
  }
  .hexagon .test:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  body {
    /* background: url('../images/bg.jpg?1394570112') 50% 50% fixed; */
    color: #c7c7c7;
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
  #page-wrapper {
    width: 822px;
    margin: 0 auto;
  }
  #page-wrapper #header {
    height: 408px;
    position: relative;
    padding: 100px 0 0 0;
    *zoom: 1;
  }
  #page-wrapper #header:after {
    content: '';
    display: table;
    clear: both;
  }
  #page-wrapper #header .header-menu {
    position: absolute;
  }
  #page-wrapper #header .header-menu a:hover,
  #page-wrapper #header .header-menu a.active {
    padding-top: 60px;
  }
  #page-wrapper #header .header-menu a:hover span,
  #page-wrapper #header .header-menu a.active span {
    margin: 15px;
  }
  #page-wrapper #header .header-menu span {
    font-family: 'Fjalla One', sans-serif;
    font-size: 35px;
    display: block;
    margin-top: 75px;
    -webkit-transition: margin 0.2s ease;
    transition: margin 0.2s ease;
  }
  #page-wrapper #header .header-menu .userpic a:hover {
    padding-top: 0;
  }
  #page-wrapper #main {
    background: #333;
    margin-top: -224px;
    min-height: 408px;
    overflow: hidden;
    -webkit-transition: height 0.3s ease;
    transition: height 0.3s ease;
    *zoom: 1;
  }
  #page-wrapper #main:after {
    content: '';
    display: table;
    clear: both;
  }
  #page-wrapper #main .wrapper {
    overflow: hidden;
  }
  #page-wrapper #main .wrapper .page {
    margin: 37px 0 0;
    overflow: hidden;
  }
  #page-wrapper #main .page-title {
    padding-top: 6px;
    text-align: center;
    z-index: 20;
  }
  #page-wrapper #main .page-title h1 {
    display: inline-block;
    position: relative;
  }
  #page-wrapper #main .block-title {
    padding-bottom: 30px;
  }
  #page-wrapper #main .content-wrapper {
    *zoom: 1;
  }
  #page-wrapper #main .content-wrapper:after {
    content: '';
    display: table;
    clear: both;
  }
  #page-wrapper #main .content-wrapper p {
    margin-bottom: 20px;
  }
  #page-wrapper #main .content-wrapper ul {
    margin-bottom: 20px;
  }
  #page-wrapper #main .content-wrapper ul li {
    /* background: url('../images/bg-li.png?1394570112') no-repeat 0 9px; */
    padding-left: 18px;
  }
  #page-wrapper #footer {
    margin-top: -220px;
    *zoom: 1;
  }
  #page-wrapper #footer:after {
    content: '';
    display: table;
    clear: both;
  }
  .skills,
  .contact {
    margin: 168px 0 0 -43px;
  }
  .skills .hexagon-in2,
  .skills a,
  .contact .hexagon-in2,
  .contact a {
    background: #ff9000;
  }

  .skills .hexagon-in2:hover,
  .skills a,
  .contact .hexagon-in2:hover,
  .contact a {
    background: #e68200;
    -webkit-transition: background 0s !important;
    transition: background 0s !important;
  }
  .contact {
    margin: 168px -43px 0 -28px;
  }
  .education,
  .work {
    margin: 66px 0 0 -28px;
  }
  .education .hexagon-in2,
  .education a,
  .work .hexagon-in2,
  .work a {
    background: #db5800;
  }

  .education .hexagon-in2:hover,
  .education a,
  .work .hexagon-in2:hover,
  .work a {
    background: #f56200;
    -webkit-transition: background 0s !important;
    transition: background 0s !important;
  }
  .userpic {
    margin: -36px 0 0 -28px;
  }
  .userpic a {
    padding: 0 !important;
  }
  .userpic a img {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 16px 0 0 -16px;
    -webkit-transition: -webkit-transform 0.1s;
    transition: -webkit-transform 0.1s;
    transition: transform 0.1s;
    transition: transform 0.1s, -webkit-transform 0.1s;
  }
  .userpic a img:hover {
    -webkit-transform: rotate(90deg) scale(1.1);
    transform: rotate(90deg) scale(1.1);
    cursor: pointer;
  }
  .medium,
  .twitter {
    margin: 0 0 0 -43px;
  }
  .medium .hexagon-in2,
  .medium a,
  .twitter .hexagon-in2,
  .twitter a {
    background: #f0c600;
    -webkit-transition: background 0.3s ease;
    -webkit-transition-delay: !important;
    transition: background 0.3s ease !important;
  }
  .medium .hexagon-in2:hover,
  .medium a:hover,
  .twitter .hexagon-in2:hover,
  .twitter a:hover {
    background: #ffda14;
    -webkit-transition: background 0s !important;
    transition: background 0s !important;
  }
  .twitter {
    margin: 0 -43px 0 -28px;
  }
  .linkedin,
  .github {
    margin: 102px 0 0 -28px;
  }
  .linkedin .hexagon-in2,
  .linkedin a,
  .github .hexagon-in2,
  .github a {
    background: #8ea106;
    -webkit-transition: background 0.3s ease;
    -webkit-transition-delay: !important;
    transition: background 0.3s ease !important;
  }
  .linkedin .hexagon-in2:hover,
  .linkedin a:hover,
  .github .hexagon-in2:hover,
  .github a:hover {
    background: #a2b51a;
    -webkit-transition: background 0s !important;
    transition: background 0s !important;
  }
  .download {
    display: none;
  }
  .copyright {
    float: left;
    width: 204px;
    height: 200px;
    text-align: center;
    color: #fff;
    margin: 220px -14px 0;
    padding: 38px 23px 0 0;
    box-sizing: border-box;
  }
  .downloadStyle {
    height: 40px;
    width: 40px;
    cursor: pointer;
  }
  .downloadStyle:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .downloadStyle:active {
    color: rgb(202, 185, 185);
  }
  label {
    color: #fff;
    font-size: 16px;
    font-family: 'PT Sans', sans-serif;
  }
  input[type='text'],
  input[type='email'],
  textarea {
    background: #292929;
    border: 1px solid #615f55;
    color: #c7c7c7;
    font-family: 'PT Sans', sans-serif;
    font-size: 15px;
    box-sizing: border-box;
  }

  #home-page h1 {
    font-size: 48px;
    margin: 0 auto;
    padding-top: 26px;
    position: relative;
    text-align: center;
    width: 580px;
    z-index: 550;
  }
  #home-page p {
    width: 530px;
    font-size: 24px;
    margin: 0 auto;
    padding-top: 5px;
    position: relative;
    line-height: 31px;
    text-align: center;
    font-family: 'PT Sans', sans-serif;
  }
  #home-page .job-title {
    font-size: 24px;
    text-align: center;
    width: 150px;
    margin: 0 auto;
    position: relative;
    z-index: 550;
    line-height: 27px;
  }
  #home-page .phone {
    font-size: 30px;
    color: #fff;
    padding-top: 16px;
    /* position: absolute;
    bottom: 20;
    height: 60px;
    width: 100px; */
    text-align: center;
  }
  .bitmoji {
    display: none;
  }
  .home-flexbox2 {
    display: none;
  }

  html,
  body {
    min-height: 100vh;
    min-width: 100vw;
    /* background: linear-gradient(103deg, #000000, #ffffff); */
    background: #111;
    background-size: 250% 250%;
  }

  .iconStyleInHexagon {
    height: 80px;
    width: 80px;
  }

  .userPicImageStyle {
    height: 204px;
    width: 236px;
  }
}

/* --- mobile layout */

@media (max-width: 600px) {
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .hexagon {
    width: 17vw;
    height: 20vw;
    margin: 5px;
    /* height: 236px; */
    overflow: hidden;
    /* visibility: hidden; */
    float: left;
    display: block;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    z-index: 10;
    outline: none;
  }
  .hexagon div.hexagon-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }
  .hexagon div.hexagon-in2 {
    color: #fff;
    overflow: hidden;
    visibility: visible;
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }
  .hexagon .inner {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    visibility: visible;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .hexagon .test {
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    visibility: visible;
    display: block;
    padding: 3.5vh 0px;

    text-decoration: none;
    font-family: 'Fjalla One', sans-serif;
    font-size: 16px;
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }
  .hexagon .test:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  body {
    /* background: url('../images/bg.jpg?1394570112') 50% 50% fixed; */
    color: #c7c7c7;
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
  #page-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  #page-wrapper #header {
    /* height: 408px; */
    height: 15vh;
    position: relative;
    *zoom: 1;
    overflow-x: scroll;
    box-shadow: 0px 1px 1px #fff;
    z-index: 100;
  }
  #page-wrapper #header:after {
    content: '';
    display: table;
    clear: both;
  }
  #page-wrapper #header .header-menu {
    position: fixed;
  }
  #page-wrapper #header .header-menu a:hover,
  #page-wrapper #header .header-menu a.active {
    padding-top: 0px;
  }
  #page-wrapper #header .header-menu a:hover span,
  #page-wrapper #header .header-menu a.active span {
    margin: 0px;
  }
  #page-wrapper #header .header-menu span {
    font-family: 'Fjalla One', sans-serif;
    font-size: 35px;
    display: block;
    -webkit-transition: margin 0.2s ease;
    transition: margin 0.2s ease;
  }
  #page-wrapper #header .header-menu .userpic a:hover {
    padding-top: 0;
  }
  #page-wrapper #main {
    height: 70vh;
    background: #333;
    overflow: scroll;
    -webkit-transition: height 0.3s ease;
    transition: height 0.3s ease;
    *zoom: 1;
  }
  #page-wrapper #main:after {
    content: '';
    display: table;
    clear: both;
  }
  #page-wrapper #main .wrapper {
    /* overflow: scroll; */
  }
  #page-wrapper #main .wrapper .page {
    /* margin: 37px 0 0; */
    /* overflow: scroll; */
  }
  #page-wrapper #main .page-title {
    padding-top: 20px;
    text-align: center;
    z-index: 20;
  }
  #page-wrapper #main .page-title h1 {
    display: inline-block;
    position: relative;
  }
  #page-wrapper #main .block-title {
    padding-bottom: 30px;
  }
  #page-wrapper #main .content-wrapper {
    *zoom: 1;
  }
  #page-wrapper #main .content-wrapper:after {
    content: '';
    display: table;
    clear: both;
  }
  #page-wrapper #main .content-wrapper p {
    margin-bottom: 20px;
  }
  #page-wrapper #main .content-wrapper ul {
    margin-bottom: 20px;
  }
  #page-wrapper #main .content-wrapper ul li {
    /* background: url('../images/bg-li.png?1394570112') no-repeat 0 9px; */
    padding-left: 18px;
  }
  #page-wrapper #footer {
    /* flex: 1; */
    height: 15vh;
    width: 100%;
    position: absolute;
    bottom: 0;
    *zoom: 1;
    z-index: 100;
  }
  #page-wrapper #footer:after {
    content: '';
    display: table;
    clear: both;
  }
  .skills,
  .contact {
  }
  .skills .hexagon-in2,
  .skills a,
  .contact .hexagon-in2,
  .contact a {
    background: #ff9000;
  }

  .skills .hexagon-in2:hover,
  .skills a,
  .contact .hexagon-in2:hover,
  .contact a {
    background: #e68200;
    -webkit-transition: background 0s !important;
    transition: background 0s !important;
  }
  .contact {
  }
  .education,
  .work {
  }
  .education .hexagon-in2,
  .education a,
  .work .hexagon-in2,
  .work a {
    background: #db5800;
  }

  .education .hexagon-in2:hover,
  .education a,
  .work .hexagon-in2:hover,
  .work a {
    background: #f56200;
    -webkit-transition: background 0s !important;
    transition: background 0s !important;
  }
  .userpic {
  }
  .userpic a {
    /* padding: 0 !important; */
  }
  .userpic a img {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 16px 0 0 -16px;
    -webkit-transition: -webkit-transform 0.1s;
    transition: -webkit-transform 0.1s;
    transition: transform 0.1s;
    transition: transform 0.1s, -webkit-transform 0.1s;
  }
  .userpic a img:hover {
    -webkit-transform: rotate(90deg) scale(1.1);
    transform: rotate(90deg) scale(1.1);
    cursor: pointer;
  }
  .medium,
  .twitter {
  }
  .medium .hexagon-in2,
  .medium a,
  .twitter .hexagon-in2,
  .twitter a {
    background: #f0c600;
    -webkit-transition: background 0.3s ease;
    -webkit-transition-delay: !important;
    transition: background 0.3s ease !important;
  }
  .medium .hexagon-in2:hover,
  .medium a:hover,
  .twitter .hexagon-in2:hover,
  .twitter a:hover {
    background: #ffda14;
    -webkit-transition: background 0s !important;
    transition: background 0s !important;
  }
  .twitter {
  }
  .linkedin,
  .github {
  }
  .linkedin .hexagon-in2,
  .linkedin a,
  .github .hexagon-in2,
  .github a {
    background: #8ea106;
    -webkit-transition: background 0.3s ease;
    -webkit-transition-delay: !important;
    transition: background 0.3s ease !important;
  }
  .linkedin .hexagon-in2:hover,
  .linkedin a:hover,
  .github .hexagon-in2:hover,
  .github a:hover {
    background: #a2b51a;
    -webkit-transition: background 0s !important;
    transition: background 0s !important;
  }

  .skills,
  .contact {
  }
  .download .hexagon-in2,
  .download a {
    background: #ff9000;
  }

  .download .hexagon-in2:hover,
  .download a {
    background: #e68200;
    -webkit-transition: background 0s !important;
    transition: background 0s !important;
  }
  .copyright {
    display: none;
    /* float: left;
    width: 204px;
    height: 200px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    margin: 220px -14px 0;
    padding: 38px 23px 0 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; */
  }
  /* label {
    color: #fff;
    font-size: 16px;
    font-family: 'PT Sans', sans-serif;
  }
  input[type='text'],
  input[type='email'],
  textarea {
    background: #292929;
    border: 1px solid #615f55;
    color: #c7c7c7;
    font-family: 'PT Sans', sans-serif;
    font-size: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  } */

  #home-page h1 {
    font-size: 48px;
    margin: 0 auto;
    padding-top: 26px;
    position: relative;
    text-align: center;
    z-index: 550;
  }
  #home-page p {
    font-size: 20px;
    margin: 0 auto;
    padding-top: 5px;
    position: relative;
    /* line-height: 31px; */
    text-align: center;
    /* padding-right: 10px; */
    margin-right: 10px;
    text-align: left;
    word-wrap: break-word;
  }
  #home-page .escr {
    color: white;
    text-align: center;
    font-size: 25px;
  }
  #home-page .job-title {
    font-size: 24px;
    text-align: center;
    margin-top: 8px;
    position: relative;
    z-index: 550;
    line-height: 27px;
  }
  #home-page .phone {
    font-size: 30px;
    color: #fff;
    padding: 16px 0px;
    text-align: center;
  }

  .bitmoji {
    padding: 10px 2px;
  }
  .bitmoji img {
    height: 200px;
    width: auto;
  }
  .home-flexbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }

  html,
  body {
    min-height: 100vh;
    min-width: 100vw;
    background: #333;
    /* background: linear-gradient(103deg, #000000, #ffffff);
    background-size: 500% 500%;

    -webkit-animation: backgroundgrad 60s ease infinite;
    -moz-animation: backgroundgrad 60s ease infinite;
    animation: backgroundgrad 60s ease infinite; */
  }

  .iconStyleInHexagon {
    height: 30px;
    width: 30px;
  }

  .userPicImageStyle {
    height: 90px;
    width: 120px;
    height: 90%;
    width: 150%;
  }
}

.Skills_row__2TDTA {
  display: flex;
  align-items: space-around;
  justify-content: space-around;
  margin: 30px 120px 120px 120px;
  flex-wrap: wrap;
}

.Skills_circle-text__2GOKQ {
  display: flex;
  height: 150px; /*change this and the width for the size of your initial circle*/
  width: 150px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  /* background: rgb(231, 92, 92); */
  background: #2b2b2b;
  color: #fff;
  font: 20px 'josefin sans', arial; /*change this for font-size and font-family*/
  text-shadow: 2px 2px 5px black;
  align-items: center;
  justify-content: center;
  margin: 20px;
  box-shadow: inset 0 0 0 0 rgba(200, 95, 66, 0.4),
    inset 0 0 0 3px rgba(255, 255, 255, 0.6), 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.Skills_circle-text__2GOKQ:hover {
  /* background: rgb(231, 56, 56); */
  background: #ff9000;
  font: 22px 'josefin sans', arial;
  text-shadow: 2px 2px 5px blue;
}

@media (max-width: 600px) {
  .Skills_circle-text__2GOKQ {
    height: 130px; /*change this and the width for the size of your initial circle*/
    width: 130px;
    font: 18px 'josefin sans', arial;
    margin: 10px;
  }
  .Skills_row__2TDTA {
    margin: 0px;
  }
}

.Education_timeline__3SlcU {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 100px;
  height: 100%;
  margin-bottom: 200px;
}

/* The actual timeline (the vertical ruler) */
.Education_timeline__3SlcU::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #fff;
  top: 0;
  bottom: 0;
  left: 20%;
  margin-left: -3px;
}

/* Container around content */
.Education_container__3HW6Q {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 55%;
  padding-left: 70px;
  padding-right: 25px;
  z-index: 0;
  margin-top: 20px;
}

/* The circles on the timeline */
.Education_container__3HW6Q::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: #2b2b2b;
  border: 4px solid #db5800;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.Education_left__3sO0n {
  left: 0;
}

/* Place the container to the right */
.Education_right__2WJOl {
  left: 20%;
}

/* Add arrows to the left container (pointing right) */
.Education_left__3sO0n::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.Education_right__2WJOl::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.Education_right__2WJOl::after {
  left: -16px;
}

/* The actual content */
.Education_content__3pKZH {
  padding: 20px 30px;
  background-color: #2b2b2b;
  position: relative;
  border-radius: 6px;
  color: black;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.Education_content__3pKZH:hover {
  /* -webkit-box-shadow: 9px 8px 51px 4px rgba(219, 88, 0, 1);
  -moz-box-shadow: 9px 8px 51px 4px rgba(219, 88, 0, 1);
  box-shadow: 9px 8px 51px 4px rgba(219, 88, 0, 1); */
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.Education_year__vrOQf {
  color: white;
  font-size: 24px;
}

.Education_educationText__3KDDD {
  text-align: justify;
  word-wrap: break-word;
  font-weight: 300;
  line-height: 1.3;
  color: #fff8dc;
  font-size: 16px;
  padding-top: 12px;
}

@media (max-width: 600px) {
  .Education_timeline__3SlcU {
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 70px;
  }

  /* The actual timeline (the vertical ruler) */
  .Education_timeline__3SlcU::after {
    left: 10%;
  }

  /* Container around content */
  .Education_container__3HW6Q {
    padding: 0px;
    width: 70%;
    padding-left: 45px;
    padding-right: 10px;
  }

  /* The circles on the timeline */
  .Education_container__3HW6Q::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }

  /* Place the container to the right */
  .Education_right__2WJOl {
    left: 10%;
  }

  /* The actual content */
  .Education_content__3pKZH {
    padding: 20px;
    position: relative;
    border-radius: 6px;
  }
  .Education_content__3pKZH:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  h2 {
    font-size: 26px;
  }
}

.wrapper_acc {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0rem 3rem 0rem; }

.last {
  padding: 3rem 0rem 10rem 0rem; }

.accordion-list {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #2b2b2b;
  max-width: 30rem;
  border-radius: 0.4rem;
  overflow: hidden; }
  .accordion-list__item {
    background-color: #2b2b2b; }
    .accordion-list__item + .accordion-list__item {
      border-top: 10px solid rgba(0, 0, 0, 0.1); }

.accordion-item--opened .accordion-item__icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.accordion-item--opened .accordion-item__inner {
  max-height: 100rem;
  -webkit-transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
          transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  -webkit-transition-property: max-height;
  transition-property: max-height; }

.accordion-item--opened .accordion-item__content {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform; }

.accordion-item__line {
  display: block;
  padding: 0.8rem 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2b2b2b;
  z-index: 0;
  position: relative; }

.accordion-item__linewrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  flex-direction: column; }

.accordion-item__title {
  font-size: 1.25rem;
  margin: 0;
  color: #fff;
  text-align: left; }

.accordion-item__subtitle {
  font-size: 1rem;
  margin: 0;
  font-weight: 400;
  color: #fff8dc;
  padding-top: 10px;
  text-align: left; }

.accordion-item__icon {
  width: 1.2rem;
  height: 1.2rem;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
  opacity: 0.6; }

.accordion-item__inner {
  max-height: 0;
  overflow: hidden;
  text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  -webkit-transition-property: max-height;
  transition-property: max-height;
  z-index: 0;
  position: relative; }

.accordion-item__content {
  opacity: 0;
  -webkit-transform: translateY(-1rem);
          transform: translateY(-1rem);
  -webkit-transition-timing-function: linear, ease;
          transition-timing-function: linear, ease;
  -webkit-transition-duration: 0.1s;
          transition-duration: 0.1s;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
  padding: 0 1.2rem 1.2rem; }

.accordion-item__paragraph {
  margin: 0;
  font-size: 1rem;
  color: #fff;
  font-weight: 300;
  line-height: 1.3;
  text-align: justify;
  word-wrap: break-word; }

.custom-link {
  font-size: 1.25rem;
  color: #fff;
  text-decoration: none;
  -webkit-transition: font-size 0.2s ease-in-out;
  transition: font-size 0.2s ease-in-out; }

.custom-link:hover {
  text-decoration: underline;
  font-size: 1.3rem; }

@media (max-width: 600px) {
  .wrapper_acc {
    padding: 20px; }
  .last {
    padding-bottom: 70px; }
  .accordion-item__line {
    padding: 0.8rem 1rem; }
  .accordion-item__linewrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    flex-direction: column; }
  .accordion-item__title {
    text-align: left; }
  .accordion-item__subtitle {
    text-align: left; } }

.container1234 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0px 100px 0px;
}
.box {
  width: 450px;
  padding: 40px;
  background: #2b2b2b;
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-bottom: 50px;
}

.box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  color: darkseagreen;
  text-align: center;
}

.box .inputBox {
  position: relative;
}

.box .inputBox input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff8dc;
  outline: none;
  background: transparent;
}

.box .inputBox label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 17px;
  color: #fff;
  pointer-events: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.box .inputBox input:focus ~ label,
.box .inputBox input:valid ~ label {
  top: -25px;
  left: 0;
  color: #ff9000;
  font-size: 14px;
}

.box input[type='submit'] {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  background: #ff9000;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  min-width: 40%;
  font-size: 16px;
}
.bitImage {
  height: 300px;
  width: auto;
}
.Thankyou-text {
  text-align: center;
  font-size: 1.4rem;
  margin: 0;
  font-weight: 400;
  color: #fff8dc;
}
@media (max-width: 600px) {
  .container1234 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 15px;
  }
  .bitImage {
    height: 200px;
    width: auto;
  }
}

