.container1234 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0px 100px 0px;
}
.box {
  width: 450px;
  padding: 40px;
  background: #2b2b2b;
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-bottom: 50px;
}

.box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  color: darkseagreen;
  text-align: center;
}

.box .inputBox {
  position: relative;
}

.box .inputBox input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff8dc;
  outline: none;
  background: transparent;
}

.box .inputBox label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 17px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.box .inputBox input:focus ~ label,
.box .inputBox input:valid ~ label {
  top: -25px;
  left: 0;
  color: #ff9000;
  font-size: 14px;
}

.box input[type='submit'] {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  background: #ff9000;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  min-width: 40%;
  font-size: 16px;
}
.bitImage {
  height: 300px;
  width: auto;
}
.Thankyou-text {
  text-align: center;
  font-size: 1.4rem;
  margin: 0;
  font-weight: 400;
  color: #fff8dc;
}
@media (max-width: 600px) {
  .container1234 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 15px;
  }
  .bitImage {
    height: 200px;
    width: auto;
  }
}
