@import url(http://fonts.googleapis.com/css?family=PT+Sans|Fjalla+One);

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
html {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}
q,
blockquote {
  quotes: none;
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: '';
  content: none;
}
a img {
  border: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Fjalla One', sans-serif;
  font-weight: normal;
  line-height: normal;
}
h1 {
  font-size: 35px;
  color: #fff;
}
h2 {
  font-size: 32px;
  color: #fff;
}
h3 {
  font-size: 24px;
  color: #fff;
}
h4 {
  font-size: 22px;
  color: #fff;
}
.hidden {
  display: none;
}

@font-face {
  font-family: 'fontello';
  font-weight: normal;
  font-style: normal;
}

@-webkit-keyframes backgroundgrad {
  0% {
    background-position: 9% 0%;
  }
  50% {
    background-position: 92% 100%;
  }
  100% {
    background-position: 9% 0%;
  }
}
@-moz-keyframes backgroundgrad {
  0% {
    background-position: 9% 0%;
  }
  50% {
    background-position: 92% 100%;
  }
  100% {
    background-position: 9% 0%;
  }
}
@keyframes backgroundgrad {
  0% {
    background-position: 9% 0%;
  }
  50% {
    background-position: 92% 100%;
  }
  100% {
    background-position: 9% 0%;
  }
}

@media (min-width: 600px) {
  .hexagon {
    width: 204px;
    height: 236px;
    overflow: hidden;
    /* visibility: hidden; */
    float: left;
    display: block;
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    transform: rotate(30deg);
    z-index: 10;
    outline: none;
  }
  .hexagon div.hexagon-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }
  .hexagon div.hexagon-in2 {
    color: #fff;
    overflow: hidden;
    visibility: visible;
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }
  .hexagon .inner {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    visibility: visible;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .hexagon .test {
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    visibility: visible;
    display: block;
    text-decoration: none;
    font-family: 'Fjalla One', sans-serif;
    font-size: 45px;
    display: block;
    transition: transform 0.1s;
  }

  .hexagon .test:active {
    color: #333;
    transform: scale(1.2);
  }

  /* 4 upper hexagons */
  .upper {
    padding: 110px 0px;
  }
  /* 4 lower hexagons */
  .lower {
    padding: 80px 0px;
  }
  .hexagon .test:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  body {
    /* background: url('../images/bg.jpg?1394570112') 50% 50% fixed; */
    color: #c7c7c7;
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
  #page-wrapper {
    width: 822px;
    margin: 0 auto;
  }
  #page-wrapper #header {
    height: 408px;
    position: relative;
    padding: 100px 0 0 0;
    *zoom: 1;
  }
  #page-wrapper #header:after {
    content: '';
    display: table;
    clear: both;
  }
  #page-wrapper #header .header-menu {
    position: absolute;
  }
  #page-wrapper #header .header-menu a:hover,
  #page-wrapper #header .header-menu a.active {
    padding-top: 60px;
  }
  #page-wrapper #header .header-menu a:hover span,
  #page-wrapper #header .header-menu a.active span {
    margin: 15px;
  }
  #page-wrapper #header .header-menu span {
    font-family: 'Fjalla One', sans-serif;
    font-size: 35px;
    display: block;
    margin-top: 75px;
    -webkit-transition: margin 0.2s ease;
    -moz-transition: margin 0.2s ease;
    -o-transition: margin 0.2s ease;
    transition: margin 0.2s ease;
  }
  #page-wrapper #header .header-menu .userpic a:hover {
    padding-top: 0;
  }
  #page-wrapper #main {
    background: #333;
    margin-top: -224px;
    min-height: 408px;
    overflow: hidden;
    -webkit-transition: height 0.3s ease;
    -moz-transition: height 0.3s ease;
    -o-transition: height 0.3s ease;
    transition: height 0.3s ease;
    *zoom: 1;
  }
  #page-wrapper #main:after {
    content: '';
    display: table;
    clear: both;
  }
  #page-wrapper #main .wrapper {
    overflow: hidden;
  }
  #page-wrapper #main .wrapper .page {
    margin: 37px 0 0;
    overflow: hidden;
  }
  #page-wrapper #main .page-title {
    padding-top: 6px;
    text-align: center;
    z-index: 20;
  }
  #page-wrapper #main .page-title h1 {
    display: inline-block;
    position: relative;
  }
  #page-wrapper #main .block-title {
    padding-bottom: 30px;
  }
  #page-wrapper #main .content-wrapper {
    *zoom: 1;
  }
  #page-wrapper #main .content-wrapper:after {
    content: '';
    display: table;
    clear: both;
  }
  #page-wrapper #main .content-wrapper p {
    margin-bottom: 20px;
  }
  #page-wrapper #main .content-wrapper ul {
    margin-bottom: 20px;
  }
  #page-wrapper #main .content-wrapper ul li {
    /* background: url('../images/bg-li.png?1394570112') no-repeat 0 9px; */
    padding-left: 18px;
  }
  #page-wrapper #footer {
    margin-top: -220px;
    *zoom: 1;
  }
  #page-wrapper #footer:after {
    content: '';
    display: table;
    clear: both;
  }
  .skills,
  .contact {
    margin: 168px 0 0 -43px;
  }
  .skills .hexagon-in2,
  .skills a,
  .contact .hexagon-in2,
  .contact a {
    background: #ff9000;
  }

  .skills .hexagon-in2:hover,
  .skills a,
  .contact .hexagon-in2:hover,
  .contact a {
    background: #e68200;
    -webkit-transition: background 0s !important;
    -moz-transition: background 0s !important;
    -o-transition: background 0s !important;
    transition: background 0s !important;
  }
  .contact {
    margin: 168px -43px 0 -28px;
  }
  .education,
  .work {
    margin: 66px 0 0 -28px;
  }
  .education .hexagon-in2,
  .education a,
  .work .hexagon-in2,
  .work a {
    background: #db5800;
  }

  .education .hexagon-in2:hover,
  .education a,
  .work .hexagon-in2:hover,
  .work a {
    background: #f56200;
    -webkit-transition: background 0s !important;
    -moz-transition: background 0s !important;
    -o-transition: background 0s !important;
    transition: background 0s !important;
  }
  .userpic {
    margin: -36px 0 0 -28px;
  }
  .userpic a {
    padding: 0 !important;
  }
  .userpic a img {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 16px 0 0 -16px;
    transition: transform 0.1s;
  }
  .userpic a img:hover {
    -webkit-transform: rotate(90deg) scale(1.1);
    -moz-transform: rotate(90deg) scale(1.1);
    -ms-transform: rotate(90deg) scale(1.1);
    -o-transform: rotate(90deg) scale(1.1);
    transform: rotate(90deg) scale(1.1);
    cursor: pointer;
  }
  .medium,
  .twitter {
    margin: 0 0 0 -43px;
  }
  .medium .hexagon-in2,
  .medium a,
  .twitter .hexagon-in2,
  .twitter a {
    background: #f0c600;
    -webkit-transition: background 0.3s ease;
    -webkit-transition-delay: !important;
    -moz-transition: background 0.3s ease !important;
    -o-transition: background 0.3s ease !important;
    transition: background 0.3s ease !important;
  }
  .medium .hexagon-in2:hover,
  .medium a:hover,
  .twitter .hexagon-in2:hover,
  .twitter a:hover {
    background: #ffda14;
    -webkit-transition: background 0s !important;
    -moz-transition: background 0s !important;
    -o-transition: background 0s !important;
    transition: background 0s !important;
  }
  .twitter {
    margin: 0 -43px 0 -28px;
  }
  .linkedin,
  .github {
    margin: 102px 0 0 -28px;
  }
  .linkedin .hexagon-in2,
  .linkedin a,
  .github .hexagon-in2,
  .github a {
    background: #8ea106;
    -webkit-transition: background 0.3s ease;
    -webkit-transition-delay: !important;
    -moz-transition: background 0.3s ease !important;
    -o-transition: background 0.3s ease !important;
    transition: background 0.3s ease !important;
  }
  .linkedin .hexagon-in2:hover,
  .linkedin a:hover,
  .github .hexagon-in2:hover,
  .github a:hover {
    background: #a2b51a;
    -webkit-transition: background 0s !important;
    -moz-transition: background 0s !important;
    -o-transition: background 0s !important;
    transition: background 0s !important;
  }
  .download {
    display: none;
  }
  .copyright {
    float: left;
    width: 204px;
    height: 200px;
    text-align: center;
    color: #fff;
    margin: 220px -14px 0;
    padding: 38px 23px 0 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .downloadStyle {
    height: 40px;
    width: 40px;
    cursor: pointer;
  }
  .downloadStyle:hover {
    transform: scale(1.1);
  }
  .downloadStyle:active {
    color: rgb(202, 185, 185);
  }
  label {
    color: #fff;
    font-size: 16px;
    font-family: 'PT Sans', sans-serif;
  }
  input[type='text'],
  input[type='email'],
  textarea {
    background: #292929;
    border: 1px solid #615f55;
    color: #c7c7c7;
    font-family: 'PT Sans', sans-serif;
    font-size: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  #home-page h1 {
    font-size: 48px;
    margin: 0 auto;
    padding-top: 26px;
    position: relative;
    text-align: center;
    width: 580px;
    z-index: 550;
  }
  #home-page p {
    width: 530px;
    font-size: 24px;
    margin: 0 auto;
    padding-top: 5px;
    position: relative;
    line-height: 31px;
    text-align: center;
    font-family: 'PT Sans', sans-serif;
  }
  #home-page .job-title {
    font-size: 24px;
    text-align: center;
    width: 150px;
    margin: 0 auto;
    position: relative;
    z-index: 550;
    line-height: 27px;
  }
  #home-page .phone {
    font-size: 30px;
    color: #fff;
    padding-top: 16px;
    /* position: absolute;
    bottom: 20;
    height: 60px;
    width: 100px; */
    text-align: center;
  }
  .bitmoji {
    display: none;
  }
  .home-flexbox2 {
    display: none;
  }

  html,
  body {
    min-height: 100vh;
    min-width: 100vw;
    /* background: linear-gradient(103deg, #000000, #ffffff); */
    background: #111;
    background-size: 250% 250%;
  }

  .iconStyleInHexagon {
    height: 80px;
    width: 80px;
  }

  .userPicImageStyle {
    height: 204px;
    width: 236px;
  }
}

/* --- mobile layout */

@media (max-width: 600px) {
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .hexagon {
    width: 17vw;
    height: 20vw;
    margin: 5px;
    /* height: 236px; */
    overflow: hidden;
    /* visibility: hidden; */
    float: left;
    display: block;
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    transform: rotate(30deg);
    z-index: 10;
    outline: none;
  }
  .hexagon div.hexagon-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }
  .hexagon div.hexagon-in2 {
    color: #fff;
    overflow: hidden;
    visibility: visible;
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }
  .hexagon .inner {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    visibility: visible;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .hexagon .test {
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    visibility: visible;
    display: block;
    padding: 3.5vh 0px;

    text-decoration: none;
    font-family: 'Fjalla One', sans-serif;
    font-size: 16px;
    transition: transform 0.2s;
  }
  .hexagon .test:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  body {
    /* background: url('../images/bg.jpg?1394570112') 50% 50% fixed; */
    color: #c7c7c7;
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
  #page-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  #page-wrapper #header {
    /* height: 408px; */
    height: 15vh;
    position: relative;
    *zoom: 1;
    overflow-x: scroll;
    -webkit-box-shadow: 0px 1px 1px #fff;
    -moz-box-shadow: 0px 1px 1px #fff;
    box-shadow: 0px 1px 1px #fff;
    z-index: 100;
  }
  #page-wrapper #header:after {
    content: '';
    display: table;
    clear: both;
  }
  #page-wrapper #header .header-menu {
    position: fixed;
  }
  #page-wrapper #header .header-menu a:hover,
  #page-wrapper #header .header-menu a.active {
    padding-top: 0px;
  }
  #page-wrapper #header .header-menu a:hover span,
  #page-wrapper #header .header-menu a.active span {
    margin: 0px;
  }
  #page-wrapper #header .header-menu span {
    font-family: 'Fjalla One', sans-serif;
    font-size: 35px;
    display: block;
    -webkit-transition: margin 0.2s ease;
    -moz-transition: margin 0.2s ease;
    -o-transition: margin 0.2s ease;
    transition: margin 0.2s ease;
  }
  #page-wrapper #header .header-menu .userpic a:hover {
    padding-top: 0;
  }
  #page-wrapper #main {
    height: 70vh;
    background: #333;
    overflow: scroll;
    -webkit-transition: height 0.3s ease;
    -moz-transition: height 0.3s ease;
    -o-transition: height 0.3s ease;
    transition: height 0.3s ease;
    *zoom: 1;
  }
  #page-wrapper #main:after {
    content: '';
    display: table;
    clear: both;
  }
  #page-wrapper #main .wrapper {
    /* overflow: scroll; */
  }
  #page-wrapper #main .wrapper .page {
    /* margin: 37px 0 0; */
    /* overflow: scroll; */
  }
  #page-wrapper #main .page-title {
    padding-top: 20px;
    text-align: center;
    z-index: 20;
  }
  #page-wrapper #main .page-title h1 {
    display: inline-block;
    position: relative;
  }
  #page-wrapper #main .block-title {
    padding-bottom: 30px;
  }
  #page-wrapper #main .content-wrapper {
    *zoom: 1;
  }
  #page-wrapper #main .content-wrapper:after {
    content: '';
    display: table;
    clear: both;
  }
  #page-wrapper #main .content-wrapper p {
    margin-bottom: 20px;
  }
  #page-wrapper #main .content-wrapper ul {
    margin-bottom: 20px;
  }
  #page-wrapper #main .content-wrapper ul li {
    /* background: url('../images/bg-li.png?1394570112') no-repeat 0 9px; */
    padding-left: 18px;
  }
  #page-wrapper #footer {
    /* flex: 1; */
    height: 15vh;
    width: 100%;
    position: absolute;
    bottom: 0;
    *zoom: 1;
    z-index: 100;
  }
  #page-wrapper #footer:after {
    content: '';
    display: table;
    clear: both;
  }
  .skills,
  .contact {
  }
  .skills .hexagon-in2,
  .skills a,
  .contact .hexagon-in2,
  .contact a {
    background: #ff9000;
  }

  .skills .hexagon-in2:hover,
  .skills a,
  .contact .hexagon-in2:hover,
  .contact a {
    background: #e68200;
    -webkit-transition: background 0s !important;
    -moz-transition: background 0s !important;
    -o-transition: background 0s !important;
    transition: background 0s !important;
  }
  .contact {
  }
  .education,
  .work {
  }
  .education .hexagon-in2,
  .education a,
  .work .hexagon-in2,
  .work a {
    background: #db5800;
  }

  .education .hexagon-in2:hover,
  .education a,
  .work .hexagon-in2:hover,
  .work a {
    background: #f56200;
    -webkit-transition: background 0s !important;
    -moz-transition: background 0s !important;
    -o-transition: background 0s !important;
    transition: background 0s !important;
  }
  .userpic {
  }
  .userpic a {
    /* padding: 0 !important; */
  }
  .userpic a img {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 16px 0 0 -16px;
    transition: transform 0.1s;
  }
  .userpic a img:hover {
    -webkit-transform: rotate(90deg) scale(1.1);
    -moz-transform: rotate(90deg) scale(1.1);
    -ms-transform: rotate(90deg) scale(1.1);
    -o-transform: rotate(90deg) scale(1.1);
    transform: rotate(90deg) scale(1.1);
    cursor: pointer;
  }
  .medium,
  .twitter {
  }
  .medium .hexagon-in2,
  .medium a,
  .twitter .hexagon-in2,
  .twitter a {
    background: #f0c600;
    -webkit-transition: background 0.3s ease;
    -webkit-transition-delay: !important;
    -moz-transition: background 0.3s ease !important;
    -o-transition: background 0.3s ease !important;
    transition: background 0.3s ease !important;
  }
  .medium .hexagon-in2:hover,
  .medium a:hover,
  .twitter .hexagon-in2:hover,
  .twitter a:hover {
    background: #ffda14;
    -webkit-transition: background 0s !important;
    -moz-transition: background 0s !important;
    -o-transition: background 0s !important;
    transition: background 0s !important;
  }
  .twitter {
  }
  .linkedin,
  .github {
  }
  .linkedin .hexagon-in2,
  .linkedin a,
  .github .hexagon-in2,
  .github a {
    background: #8ea106;
    -webkit-transition: background 0.3s ease;
    -webkit-transition-delay: !important;
    -moz-transition: background 0.3s ease !important;
    -o-transition: background 0.3s ease !important;
    transition: background 0.3s ease !important;
  }
  .linkedin .hexagon-in2:hover,
  .linkedin a:hover,
  .github .hexagon-in2:hover,
  .github a:hover {
    background: #a2b51a;
    -webkit-transition: background 0s !important;
    -moz-transition: background 0s !important;
    -o-transition: background 0s !important;
    transition: background 0s !important;
  }

  .skills,
  .contact {
  }
  .download .hexagon-in2,
  .download a {
    background: #ff9000;
  }

  .download .hexagon-in2:hover,
  .download a {
    background: #e68200;
    -webkit-transition: background 0s !important;
    -moz-transition: background 0s !important;
    -o-transition: background 0s !important;
    transition: background 0s !important;
  }
  .copyright {
    display: none;
    /* float: left;
    width: 204px;
    height: 200px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    margin: 220px -14px 0;
    padding: 38px 23px 0 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; */
  }
  /* label {
    color: #fff;
    font-size: 16px;
    font-family: 'PT Sans', sans-serif;
  }
  input[type='text'],
  input[type='email'],
  textarea {
    background: #292929;
    border: 1px solid #615f55;
    color: #c7c7c7;
    font-family: 'PT Sans', sans-serif;
    font-size: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  } */

  #home-page h1 {
    font-size: 48px;
    margin: 0 auto;
    padding-top: 26px;
    position: relative;
    text-align: center;
    z-index: 550;
  }
  #home-page p {
    font-size: 20px;
    margin: 0 auto;
    padding-top: 5px;
    position: relative;
    /* line-height: 31px; */
    text-align: center;
    /* padding-right: 10px; */
    margin-right: 10px;
    text-align: left;
    word-wrap: break-word;
  }
  #home-page .escr {
    color: white;
    text-align: center;
    font-size: 25px;
  }
  #home-page .job-title {
    font-size: 24px;
    text-align: center;
    margin-top: 8px;
    position: relative;
    z-index: 550;
    line-height: 27px;
  }
  #home-page .phone {
    font-size: 30px;
    color: #fff;
    padding: 16px 0px;
    text-align: center;
  }

  .bitmoji {
    padding: 10px 2px;
  }
  .bitmoji img {
    height: 200px;
    width: auto;
  }
  .home-flexbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }

  html,
  body {
    min-height: 100vh;
    min-width: 100vw;
    background: #333;
    /* background: linear-gradient(103deg, #000000, #ffffff);
    background-size: 500% 500%;

    -webkit-animation: backgroundgrad 60s ease infinite;
    -moz-animation: backgroundgrad 60s ease infinite;
    animation: backgroundgrad 60s ease infinite; */
  }

  .iconStyleInHexagon {
    height: 30px;
    width: 30px;
  }

  .userPicImageStyle {
    height: 90px;
    width: 120px;
    height: 90%;
    width: 150%;
  }
}
